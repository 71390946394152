import axios from 'axios'

export const createQuestion = async (questionnaireId, {question, redtrainId}) => {
  await axios.post(`poll/${questionnaireId}/question`, {
    question,
    redtrain_id: redtrainId
  })
}

export const updateQuestion = async (questionId, {question, redtrainId}) => {
  await axios.put(`poll/question/${questionId}`, {
    question,
    redtrain_id: redtrainId
  })
}

export const deleteQuestion = async (questionId) => {
  await axios.delete(`poll/question/${questionId}`)
}
