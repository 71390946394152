<template>
  <ModalTemplate
    :isOpen="!!id"
    @closed="close"
    persistent
  >
    <template slot="title">
      {{data.title}}
    </template>
    <template slot="content">
      <Loading :value="loading"/>
      <QuestionsFormList
        :questions="data.questions"
        :questionnaireId="id"
        @fetchQuestions="fetchData"
      />
    </template>
    <template v-slot:footer>
      <v-btn color="info" text @click="close">
        Zamknij
      </v-btn>
    </template>
  </ModalTemplate>
</template>

<script>
import ModalTemplate from "@/templates/ModalTemplate";
import {clone} from "@/utils/dataTransformation";
import {notifyStandardError} from "@/services/notificationService";
import {fetchQuestionnaire} from "@/services/questionnaireService";
import QuestionsFormList from "@/components/questionnaires/Questions/QuestionsFormList";
import Loading from "@/components/Loading";

const emptyData = {
  title: '',
  questions: []
}

export default {
  name: 'QuestionnaireShow',
  components: {Loading, QuestionsFormList, ModalTemplate},
  props: {
    id: [String, Number]
  },
  data: () => ({
    loading: false,
    data: clone(emptyData)
  }),
  beforeMount() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true
        this.data = await fetchQuestionnaire(this.id)
      } catch (e) {
        notifyStandardError()
      } finally {
        this.loading = false
      }
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
