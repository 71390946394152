<template>
  <v-progress-linear
    v-show="value"
    indeterminate
    color="primary"
  />
</template>

<script>
export default {
  name: "Loading",
  props: {
    value: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
