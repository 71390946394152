<template>
  <ModalTemplate
    :isOpen="!!questionnaireId"
    persistent
  >
    <template v-slot:title>
      <h3 class="headline">
        {{ questionnaireId === 'create' ? 'Nowa ankieta' : 'Edycja ankiety' }}
      </h3>
    </template>
    <template v-slot:content>
      <v-form ref="form" @submit.prevent="save">
        <v-container>
          <v-row>
            <v-col :cols="12" sm="6">
              <v-text-field
                v-model="form.title"
                label="Nazwa ankiety"
                :rules="rules.title"
                :loading="fetchLoading"
                clearable
              />
            </v-col>
            <v-col :cols="12" sm="6">
              <v-text-field
                v-model="form.redtrainId"
                label="RedTrain Id"
                :loading="fetchLoading"
                clearable
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
    <template v-slot:footer>
      <v-btn color="primary" :loading="saveLoading" text @click="save">
        Zapisz
      </v-btn>
      <v-btn color="info" :disabled="saveLoading" text @click="close">
        Anuluj
      </v-btn>
    </template>
  </ModalTemplate>
</template>

<script>
import ModalTemplate from "@/templates/ModalTemplate";
import {required} from "@/utils/validationRules";
import {notify, notifyStandardError} from "@/services/notificationService";
import {clone} from "@/utils/dataTransformation";
import {fetchQuestionnaire, saveQuestionnaire} from "@/services/questionnaireService";

const emptyForm = {
  title: '',
  redtrainId: ''
}

export default {
  name: 'QuestionnaireForm',
  components: {ModalTemplate},
  props: {
    questionnaireId: [String, Number]
  },
  data: () => ({
    saveLoading: false,
    fetchLoading: false,
    rules: {
      title: [required]
    },
    form: clone(emptyForm)
  }),
  beforeMount() {
    if (!this.isCreateMode) {
      this.fetchQuestionnaire()
    }
  },
  computed: {
    isCreateMode() {
      return this.questionnaireId === 'create'
    }
  },
  methods: {
    async save() {
      if (this.$refs.form.validate()) {
        try {
          this.saveLoading = true
          await saveQuestionnaire(this.form, !this.isCreateMode ? this.questionnaireId : null)
          this.emitRefresh()
          this.close()
          notify('success', `Ankieta została ${this.isCreateMode ? 'stworzone' : 'zmieniona'}`)
        } catch (e) {
          notifyStandardError()
        } finally {
          this.saveLoading = false
        }
      }
    },
    emitRefresh() {
      this.$emit('refreshList')
    },
    close() {
      this.$refs.form.resetValidation()
      this.$emit('close')
    },
    async fetchQuestionnaire() {
      try {
        this.fetchLoading = true;
        this.form = await fetchQuestionnaire(this.questionnaireId)
      } catch (e) {
        notifyStandardError()
      } finally {
        this.fetchLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.row > * {
  padding-left: 6px;
  padding-right: 6px;
}
</style>
