<template>
  <v-card class="my-2">
    <v-container>
      <v-row>
        <v-col cols="12" sm="8" md="9">
          <v-form
            v-if="editMode"
            class="row row--dense"
            ref="form"
            @submit.prevent="save"
          >
            <v-col cols="12" sm="7">
              <v-text-field
                :label="`${number}. Pytanie`"
                :rules="inputRules"
                :error="errorMessage"
                :value="editText || text"
                clearable
                @input="inputQuestionText"
              />
            </v-col>
            <v-col cols="12" sm="5">
              <v-text-field
                label="RedTrain Id"
                :value="editRedtrainId || redtrainId"
                clearable
                @input="inputQuestionRedtrainId"
              />
            </v-col>
          </v-form>
          <v-row v-else>
            <v-col cols="12" sm="7">
              <TextInfo
                :label="`${number}. Pytanie`"
                :value="text"
              />
            </v-col>
            <v-col cols="12" sm="5" v-if="redtrainId">
              <TextInfo
                label="RedTrain Id"
                :value="redtrainId"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="4" md="3" class="d-flex flex-wrap align-center justify-end">
          <template v-if="editMode">
            <v-btn
              color="success"
              small
              :loading="loading"
              @click="save"
            >
              Zapisz
            </v-btn>
            <v-btn
              v-if="id !== 'new'"
              color="error"
              small
              class="ml-3"
              @click="toggleEditableMode"
            >
              Anuluj
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              color="primary"
              small
              class="my-1 mb-md-0"
              @click="toggleEditableMode"
            >
              Edytuj
            </v-btn>
            <v-btn
              v-if="id !== 'new'"
              color="error"
              small
              class="ml-3 my-1 mb-md-0"
              :loading="loading"
              @click="remove"
            >
              Usuń
            </v-btn>
          </template>
        </v-col>
      </v-row>
    </v-container>
    <!--    <v-divider/>-->
  </v-card>
</template>

<script>
import {required} from "@/utils/validationRules";
import TextInfo from "@/components/TextInfo";

export default {
  name: "Question",
  components: {TextInfo},
  props: {
    number: Number,

    id: [String, Number],
    editMode: Boolean,
    text: String,
    redtrainId: String,
    errorMessage: String,
    loading: Boolean
  },
  data: () => ({
    inputRules: [required],
    editText: '',
    editRedtrainId: ''
  }),
  methods: {
    inputQuestionText(text) {
      this.editText = text
    },
    inputQuestionRedtrainId(text) {
      this.editRedtrainId = text
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$emit('save', this.id, {
          text: this.editText || this.text,
          redtrainId: this.editRedtrainId || this.redtrainId
        })
      }
    },
    remove() {
      this.$emit('delete', this.id)
    },
    toggleEditableMode() {
      this.$emit('toggleEditableMode', this.id)
    },
  }
}
</script>

<style lang="scss" scoped>
.question-text {
  font-size: 18px;
  margin-top: 20px;
}
</style>
