<template>
  <v-data-table
    :headers="headers"
    :items="data"
    :loading="loading"
    class="elevation-0 row-pointer"
    :server-items-length="pagination.totalElements"
    :footer-props="{'items-per-page-options':[10, 30, 50, 100]}"
    @update:options="optionsUpdated"
    @click:row="onRowClicked"
  >
    <template v-slot:item.actions="{ item }">
      <v-btn
        color="primary"
        icon
        @click.stop="edit(item.id)"
      >
        <v-icon size="18">
          mdi-square-edit-outline
        </v-icon>
      </v-btn>
      <v-btn
        color="red"
        icon
        @click.stop="remove(item.id)"
      >
        <v-icon size="18">
          mdi-delete
        </v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'QuestionnairesList',
  props: {
    data: Array,
    loading: Boolean,
    pagination: {
      default: null
    }
  },
  data: () => ({
    headers: [
      {
        text: 'ID',
        value: 'id',
        sortable: true,
      },
      {
        text: 'Nazwa',
        value: 'name',
        sortable: true,
      },
      {
        text: 'Liczba pytań',
        value: 'questionQuantity',
        sortable: true,
      },
      {
        text: 'RedTrain Id',
        value: 'redtrainId',
        sortable: true,
      },
      {
        text: 'Akcje',
        value: 'actions',
        sortable: false,
        align: 'end'
      },
    ]
  }),
  methods: {
    remove(id) {
      this.$emit('remove', id)
    },
    edit(id) {
      this.$emit('edit', id)
    },
    refreshData() {
      this.$emit('fetchData')
    },
    optionsUpdated({page, itemsPerPage, sortBy, sortDesc}) {
      this.pagination.page = page
      this.pagination.perPage = itemsPerPage
      this.pagination.sortBy = sortBy[0]
      this.pagination.orderBy = sortDesc[0] ? 'desc' : 'asc'

      this.refreshData()
    },
    onRowClicked(element) {
      this.$emit('rowClick', element)
    }
  }
}
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
