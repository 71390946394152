<template>
  <div>
    <span>{{label}}</span>
    <span class="value">{{value}}</span>
  </div>
</template>

<script>
export default {
  name: 'TextInfo',
  props: {
    label: [String, Number],
    value: [String, Number]
  }
}
</script>

<style scoped>
.value {
  color: black;
  display: block;
}
</style>
